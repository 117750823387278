import React, {useState, useEffect} from 'react'
import {fire} from '../firebase'
import {Route, Redirect, useHistory} from 'react-router-dom'
import {useAuth} from '../contexts/AuthContext'
import './LoginStyle.scss'


const Login = ({theUser}) => {
    const history = useHistory();
    const [email, setEmail] = useState('')
    const [pass, setPass] = useState('')
    const [user, setUser] = useState('')
    const { signIn, currentUser } = useAuth()

    const handleLogin = async () => {


        console.log("Logging")
        try {
            await signIn(email, pass)
            history.push("/welcome")
        } catch {
            console.log("Could not sign in ")
        }
        // fire.auth().signInWithEmailAndPassword(email, pass)
        // .then(resp => {
        //     console.log("Logged in", resp)
        //     setUser(resp.user)
        //     history.push("/welcome")
        // }) 
        // .catch(err => {
        //     console.log("Error " + err.message)
        // })
    }

    // const handleSubmit = async (e) => {
    //     e.preventDefault()
    //     try{
    //        await login(email,pass)
    //     } catch {
    //         console.log("ERROR loggin in ")
    //     }
        
    // }
   //console.log("CURRENT USER LOGIN " + JSON.stringify(currentUser))
    return (
        <>
            <div className ="App">
                <div className="loginForm">
                    <h1>Admin Login</h1>
                    <div className="inputField">
                    <input className="input" type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)}></input>
                    </div>
                    <div className="inputField">
                        <input className="input" type="password" placeholder="Password" required value={pass} onChange={e => setPass(e.target.value)}></input>
                    </div>
                    <div className="buttonContainer">
                        <button className="submitBtn" onClick={() => handleLogin()}>Submit</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login