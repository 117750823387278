import React, {useState} from 'react'
import './ImageUploadStyle.scss'

const ImageUpload = ({theImage, onFileChange}) => {
    return (
        <>
            <input type="file" onChange={(onFileChange)} accept="image/*"/>
           <div className="img__polaroid">
                <img src={theImage} alt="Paris" height="200px"/>
           </div>
        </>
    )
}


export default ImageUpload