import React , {useEffect, useState} from 'react'
import { Dropdown } from 'semantic-ui-react'
import {getAllMosques} from '../firebase'
const friendOptions = [
    {
      key: 'Jenny Hess',
      text: 'Jenny Hess',
      value: 'Jenny Hess',
      image: { avatar: true, src: '/images/avatar/small/jenny.jpg' },
    },
    {
      key: 'Elliot Fu',
      text: 'Elliot Fu',
      value: 'Elliot Fu',
      image: { avatar: true, src: '/images/avatar/small/elliot.jpg' },
    },
    {
      key: 'Stevie Feliciano',
      text: 'Stevie Feliciano',
      value: 'Stevie Feliciano',
      image: { avatar: true, src: '/images/avatar/small/stevie.jpg' },
    },
    {
      key: 'Christian',
      text: 'Christian',
      value: 'Christian',
      image: { avatar: true, src: '/images/avatar/small/christian.jpg' },
    },
    {
      key: 'Matt',
      text: 'Matt',
      value: 'Matt',
      image: { avatar: true, src: '/images/avatar/small/matt.jpg' },
    },
    {
      key: 'Justen Kitsune',
      text: 'Justen Kitsune',
      value: 'Justen Kitsune',
      image: { avatar: true, src: '/images/avatar/small/justen.jpg' },
    },
  ]

const fetchAllMosques = async () => {
    const getMos = getAllMosques().then(m => {
        return m.map(function(obj){
            return {
                id: obj.key,
                text: String(obj.Name),
                value: String(obj.Name),
                key: String(obj.Name),
                image: { avatar: true, src: '/images/avatar/small/jenny.jpg' }
              }

        })
    })
    return getMos
}

const DropDownMosqueMenu = ({selectedMosque}) => {

    const [allMasjids, setAllMasjids] = useState(null)

    useEffect(() => {
        console.log("loading mosq")
        async function loadM() {
            const theMAS =  await fetchAllMosques();
            setAllMasjids(theMAS)
        }
        loadM()

        console.log(allMasjids)
    
    }, []   )
    
  

    return (
        <>
            <Dropdown
                placeholder='Select Mosque'
                fluid
                selection
                options={allMasjids}
                onChange={e => selectedMosque(e.target.textContent,allMasjids )}
            />
        </>
    )
}

export default DropDownMosqueMenu