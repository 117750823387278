import React, { useState } from 'react'
import SocialOption from '../SocialOptions/SocialOption'
import ImageIcon from '@material-ui/icons/Image';
import "./InputSocialStyle.scss"
import DropDownMosqueMenu from '../DropDownMenu/DropDownMenu';
import ImageUpload from '../ImageUpload/ImageUpload'
import {storage, uploadSocialMedia} from '../firebase'
import {useAuth} from '../contexts/AuthContext'

const InputSocial = () => {

    const {currentUser} = useAuth()
    const [showMenu, setShowMenu] = useState(false)
    const [showPicture, setShowPicture] = useState(false)
    const [type, setType] = useState('')
    const [fileUrl, setFileUrl] = useState('http://placekitten.com/200/300')
    const [file, setFile] = useState('http://placekitten.com/200/300')
    const [title, settitle] = useState('')
    const [mosqueId, setMosqueId] = useState('')
    const [content, setContent] = useState('')

    const selectedMosque = (mosqueName, mosqArr) => {
        console.log("the data", mosqArr)
        const mosque = mosqArr.find(item => item.text == mosqueName)
        console.log("seelected mosque" , mosqueName)
        setMosqueId(mosque.id)
    }

    const titleChange = (event) => {
        console.log(event.target.value)
        settitle(event.target.value)
    }
    
    const contentOnChange = (event) => {
        console.log(event.target.value)
        setContent(event.target.value)
    }

    const uploadSocialFeed = () => {
        console.log("the user", currentUser)
        console.log("Uploading feed")
        const uploadTask = storage.ref(`socialfeed/${file.name}`).put(file)
        if (type != "Photo"){
            const socialFeedObj = {
                title : title,
                imageUrl : '',
                content : content,
                mosqueID : mosqueId,
                postType : type
            }

            uploadSocialMedia(socialFeedObj)


        }else{
            uploadTask.on('state_changed', (snapshot) => {
                console.log("in progress uploading")
            }, (error) => {
                console.log("error uploading image", error)
            }, () => {
                storage.ref('socialfeed').child(file.name).getDownloadURL().then(url => {
                    console.log(url)
                    const socialFeedObj = {
                        title : title,
                        imageUrl : url,
                        content : content,
                        mosqueID : mosqueId,
                        postType : type
                    }

                    uploadSocialMedia(socialFeedObj)

                })
         });

        }
    }

    const onFileChange = (e) => {
        setFile(e.target.files[0])
        setFileUrl(URL.createObjectURL(e.target.files[0]))
        
    }

    const handleMenu = (val, title) => {
        if (title === "Photo") {
            setShowPicture(val)
            setShowMenu(val)
            setType(title)
        }else{
            setShowMenu(val)
            setShowPicture(false)
            setType(title)
        }
    }
    return (
        <>
         <div className="social__feed">
                <div className="social__container">
                    <div className="social__input">
                        <form className = "form__social">
                            <input type="text" placeholder="Title" value={title} onChange={titleChange}/>
                        </form>
                    </div>
                    <div className="social__options">
                        <SocialOption title="Post" optionSelected={() => handleMenu(true, "Post")}/>
                        <SocialOption title="Photo"  optionSelected={() => handleMenu(true, "Photo")}/>
                        <SocialOption title="Live" optionSelected={() => handleMenu(true, "Live")}/>
                    </div>
                    <div className="dropdown__menu">
                        {showMenu ? 
                            <DropDownMosqueMenu selectedMosque={selectedMosque}/> :
                            null
                        }
                        {showPicture ? 
                            <ImageUpload theImage={fileUrl} onFileChange={onFileChange}/>
                                : null}
                    </div>     
                    <div className="social__input">      
                        <textarea placeholder="content" name='multiline_ip' rows='3' cols='15' className="content__input" value={content} onChange={contentOnChange}></textarea>
                    </div>   
                    <button type="submit" onClick={uploadSocialFeed}> Upload</button>  
                </div>
            </div>
        </>
    )
}

export default InputSocial