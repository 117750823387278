import React, { useState } from 'react'
import "./InputSocialStyle.scss"
import {addJanaza} from '../firebase'
import DateTimePicker from 'react-datetime-picker';

const JanazaUpload = () => {
    const [name, setName] = useState('')
    const [location, setLocation] = useState('')
    const [burial, setBurial] = useState('')
    const [atDateTime, setAtDateTime] = useState(undefined)

    const nameOnChange = (event) => {
        setName(event.target.value)
    }
    
    const locationOnChange = (event) => {
        setLocation(event.target.value)
    }

    const burialOnChange = (event) => {
        setBurial(event.target.value)
    }

    const uploadSocialFeed = () => {
        if (
            name === "" || name === undefined ||
            location === "" || location === undefined ||
            burial === "" || name === undefined ||
            !atDateTime
        ) {
            alert("Please fill in all details.")
            return
        }
        const socialFeedObj = {
            name,
            imageUrl : '',
            janazaDateTime: new Date(),
            location,
            burial,
            mosqueID : '',
            postType : 'Janaza'
        }

        addJanaza(socialFeedObj)
    }

    return (
        <>
         <div className="social__feed">
                <div className="social__container">
                    <div className="social__input">
                        <form className = "form__social">
                            <input type="text" placeholder="Name" value={name} onChange={nameOnChange}/>
                        </form>
                    </div>
                    <div className="social__input">      
                        <textarea placeholder="Location" name='multiline_ip' rows='5' cols='15' className="content__input" value={location} onChange={locationOnChange}></textarea>
                    </div>   
                    <div className="social__input">      
                        <textarea placeholder="Burial Site" name='multiline_ip' rows='5' cols='15' className="content__input" value={burial} onChange={burialOnChange}></textarea>
                    </div>   
                    <div>
                        <DateTimePicker
                            onChange={setAtDateTime}
                            value={atDateTime}
                        />
                    </div>
                    <button type="submit" onClick={uploadSocialFeed}> Upload</button>  
                </div>
            </div>
        </>
    )
}

export default JanazaUpload