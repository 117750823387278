import React from 'react'
import {useHistory} from 'react-router-dom'
import {useAuth} from '../contexts/AuthContext'
import {addJanaza} from '../firebase'

const Hero = () => {
    const history = useHistory();
    const { signOut, currentUser } = useAuth()

    const logOut = async () => {
        console.log("Logout")

        try {
            await signOut()
            history.push('/')

        } catch {
            console.log("error loggin out")
        }
    }

    return (
        <>
            <h1>WELCOME</h1>
            <button
                onClick={
                    () => {
                        history.push("/uploadPrayerTimes")
                    }
                } 
            >
                Upload Prayer Times
            </button>
            <button
                onClick={
                    () => {
                        history.push("/uploadJummahTimes")
                    }
                } 
            >
                Upload Jummah Times
            </button>
            <button
                onClick={
                    () => {
                        history.push("/socialFeedUpload")
                    }
                } 
            >
                Upload Social Media
            </button>
            <button
                onClick={
                    () => {
                        history.push("/janazaUpload")
                    }
                } 
            >
                Upload Janaza Information
            </button>
            <button
                onClick={
                    () => {
                        history.push("/hadithOfTheDay")
                    }
                } 
            >
                Upload Hadith Of The Day
            </button>

            <button
                onClick = { () => logOut()}
            >
                Logout
            </button>
        </>
    )
}

export default Hero