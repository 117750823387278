import React from 'react'
import {Redirect, Route } from 'react-router-dom'
import {useAuth} from '../contexts/AuthContext'


export default function PrivateRoute ( {component : Component, ...rest } ) {
    console.log("Private route")
    const { signIn, currentUser }  = useAuth()
    console.log("Current user is " + JSON.stringify(currentUser))
    return (
       <Route 
            {...rest}
            render = {props => {
                return currentUser ? <Component {...props} /> : <Redirect to= "/" />
            }}
        >
       </Route>
    )
}

