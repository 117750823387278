import React, {useContext, useState, useEffect} from 'react'
import {auth} from '../firebase'



const AuthContext = React.createContext()


export function useAuth () {
    return useContext(AuthContext)
}

export function AuthProvider ({children}) {

    const [currentUser, setCurrentUser] = useState(null)

    function signIn (email,password ) {
        return auth.signInWithEmailAndPassword(email,password)
    }

    function signOut () {
        return auth.signOut()
    }

    useEffect(() => {
       const unSubscribe = auth.onAuthStateChanged(user => {
            console.log("Unsubscribe " + user)
            setCurrentUser(user)
        })
        return unSubscribe
    }, [])
    
    const value = {
        currentUser,
        signIn,
        signOut
    }
    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}