import React from 'react'
import './SocialOptionStyle.scss'

const SocialOption = ({title, colour, ICON, optionSelected}) => {
    return (
        <>
            <div className="option" onClick={optionSelected}>
                <h4>
                    {title}
                </h4>

            </div>
        </>
    )
}


export default SocialOption