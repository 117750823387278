import firebase from "firebase";
import "firebase/auth";
import "firebase/storage";
import moment from "moment";

const firebaseConfig = {
  apiKey: "AIzaSyBkWEh22bURTFOfP05CMsURVDp7WS92WIQ",
  authDomain: "huddersfield-prayer-times.firebaseapp.com",
  projectId: "huddersfield-prayer-times",
  storageBucket: "huddersfield-prayer-times.appspot.com",
  messagingSenderId: "480265120750",
  appId: "1:480265120750:web:facdb1d438398400b03cff",
};

// Initialize Firebase
const fire = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

const uploadSocialMedia = (content) => {
  db.collection("Social Feed")
    .add({ ...content, date: new Date() })
    .then((docRef) => {
      console.log("Document written ", docRef.id);
    });
};

const addJanaza = (content) => {
  db.collection("Social Feed")
    .add({
      ...content,
      date: new Date(),
    })
    .then((docRef) => {
      console.log("Document written ", docRef.id);
    });
};

const uploadPrayerTimeValues = (content, id) => {
  if (id === null) {
    return;
  }
  let batch = db.batch();

  content.forEach((doc) => {
    if (doc[0] === "" || doc.length === 0) {
      return;
    }

    let date = moment(doc[0], "DD/MM/YYYY").format("YYYY-MM-DD");

    var docRef = db
      .collection("Mosques")
      .doc(id)
      .collection("Prayer Times")
      .doc(date);
    if (doc[11] && doc[11] !== "") {
      batch.set(docRef, {
        fajr: doc[1],
        sunrise: doc[2],
        dhuhr: doc[3],
        asr: doc[4],
        maghrib: doc[5],
        isha: doc[6],
        fajrJamaat: doc[7],
        dhuhrJamaat: doc[8],
        asrJamaat: doc[9],
        ishaJamaat: doc[10],
        sehri: doc[11],
      });
    } else {
      batch.set(docRef, {
        fajr: doc[1],
        sunrise: doc[2],
        dhuhr: doc[3],
        asr: doc[4],
        maghrib: doc[5],
        isha: doc[6],
        fajrJamaat: doc[7],
        dhuhrJamaat: doc[8],
        asrJamaat: doc[9],
        ishaJamaat: doc[10],
      });
    }
  });

  batch
    .commit()
    .then(() => {
      console.log("Uploaded Data");
    })
    .catch((error) => {
      console.error(error);
    });
};

const uploadJummahTimeValues = (content) => {
  let batch = db.batch();

  content.forEach((doc) => {
    let date =
      doc[0].split("-")[2] +
      "-" +
      doc[0].split("-")[1] +
      "-" +
      doc[0].split("-")[0];
    var docRef = db.collection("Jummah").doc(date);
    batch.set(docRef, {
      "Anwar E Madina": [doc[1], doc[2]],
      "Eden Centre Highfields": [doc[3], doc[4]],
      "Eden Centre Lindley": [doc[5], doc[6]],
      "Madni Jamia Masjid": [doc[7], doc[8]],
      "Masjid Abu Bakr": [doc[9], doc[10]],
      "Masjid Bilal": [doc[11], doc[12]],
      "Masjid Ghausia": [doc[13], doc[14]],
      "Masjid Hanfia": [doc[15], doc[16]],
      "Masjid Ibrahim": [doc[17], doc[18]],
      "Masjid Noor": [doc[19], doc[20]],
      "Masjid Omar": [doc[21], doc[22]],
      "Masjid Riza": [doc[23], doc[24]],
      "Masjid Shah Jalal": [doc[25], doc[26]],
      "Masjid Usman": [doc[27], doc[28]],
      "Elland Masjid": [doc[29], doc[30]],
      "Masjid Iman": [doc[31], doc[32]],
      "Northfield Hall": [doc[33], doc[34] || ""],
    });
  });

  batch
    .commit()
    .then(() => {
      console.log("Uploaded Data to firestore");
    })
    .catch((error) => {
      console.error(error);
    });
};

const addVerseOfTheDay = (date, data) => {
  db.collection("Verse Of The Day")
    .doc(date)
    .set(data)
    .then((ref) => {
      console.log(ref);
    });
};

const duplicateVerses = async (dates) => {
  const verses = await db.collection("Verse Of The Day").get();

  let allVerses = {};

  verses.forEach((verse) => {
    allVerses[verse.id] = verse.data();
  });

  let batch = db.batch();

  dates.forEach((date) => {
    const copying = allVerses[date.original_date];
    date.new_dates.forEach((dateNew) => {
      var docRef = db.collection("Verse Of The Day").doc(dateNew);
      batch.set(docRef, copying);
    });
  });

  batch
    .commit()
    .then(() => {
      console.log("Uploaded Data");
    })
    .catch((error) => {
      console.error(error);
    });
};

const storage = firebase.storage();

const getAllMosques = async () => {
  const data = db
    .collection("Mosques")
    .get()
    .then((snapshot) => {
      return snapshot.docs.map((x) => ({ ...x.data(), key: x.id }));
    });

  return await data;
};

const getHadithData = async () => {
  const data = await db
    .collection("Hadith Of The Day")
    .get()
    .then((snapshot) => {
      return snapshot.docs[0].data();
    });

  return data;
};

const setHadithData = (_data) => {
  const data = db
    .collection("Hadith Of The Day")
    .doc("Hadith")
    .set(_data)
    .then((ref) => {
      console.log(ref);
    });
};

export {
  fire,
  uploadPrayerTimeValues,
  getAllMosques,
  getHadithData,
  setHadithData,
  storage,
  addJanaza,
  uploadSocialMedia,
  addVerseOfTheDay,
  duplicateVerses,
  uploadJummahTimeValues,
};
export const auth = fire.auth();
