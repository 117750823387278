import React, { useEffect, useState } from 'react'
import "./HadithStyle.scss"
import {getHadithData, setHadithData} from '../firebase'
import DateTimePicker from 'react-datetime-picker';

const HadithOfTheDay = () => {

    const [data, setData] = useState()
    
    useEffect(async () => {
        const data = await getHadithData()

        console.log(data.Title)
        console.log(data)

        setData(data)
    }, [])

    const changeBook = (event) => {
        const newData = {}
        Object.assign(newData, data);
        newData.Book = event.target.value
        setData(newData)
    }

    const changeTitle = (event) => {
        const newData = {}
        Object.assign(newData, data);
        newData.Title = event.target.value
        setData(newData)
    }

    const changeTexts = (event) => {
        const newData = {}
        Object.assign(newData, data);
        newData.Texts = event.target.value.split("\n")
        setData(newData)
    }

    const uploadNewData = () => {
        if (data.Title !== "") {
            setHadithData(data)
        }
    }

    return (
        <>
         <div className="social__feed">
                <div className="social__container">
                    {data && <div>
                        <div className="social__input">
                            <input type="text" placeholder="Book" value={data.Book} onChange={changeBook}/>
                        </div>
                        <div className="social__input">
                            <input type="text" placeholder="Title" value={data.Title} onChange={changeTitle}/>
                        </div>
                        <div className="social__input">
                            <textarea placeholder="content" name='multiline_ip' rows='5' cols='15' className="content__input" value={data.Texts.join("\n")} onChange={changeTexts}></textarea>
                        </div>
                        <button type="submit" onClick={uploadNewData}> Upload</button> 
                    </div>}
                </div>
            </div>
        </>
    )
}

export default HadithOfTheDay