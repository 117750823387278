import React, {useRef} from 'react'
import {uploadJummahTimeValues} from '../firebase'
import './UploadJummahTimesStyles.scss'

const UploadJummahTimes = () => {
    let fileReader;
    let fileContents = null;

    const readFile = () => {
        const content = fileReader.result;

        // convert the content to JSON format
        const csv=require('csvtojson')
        csv({
            noheader:true,
            output: "csv"
        })
        .fromString(content)
        .then((csvRow)=>{ 
            fileContents = csvRow
        })  
    }

    const handleFileInput = (e) => {
        fileReader = new FileReader();
        fileReader.onloadend = readFile;
        fileReader.readAsText(e.target.files[0]);
    }

    return (
        <>
            <h1>Upload Jummah Times</h1>
            <input
                type="file"
                onChange={handleFileInput}
                accept=".csv"
            />
            <br />
            <button
                onClick={
                    () => {
                        uploadJummahTimeValues(fileContents.slice(1))
                    }
                } 
            >
                Upload
            </button>
        </>
    )
}

export default UploadJummahTimes