import React, {useState, useEffect} from 'react'
import InputSocial from '../InputSocial/InputSocial'
import './SocialFeedUploadStyle.scss'

const SocialFeedUpload = () => {
    return (
        <>
        <div className="social__page">
            <InputSocial />
        </div>
        </>
    )
}

export default SocialFeedUpload