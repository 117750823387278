import React, {useState} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Login from './Login/Login'
import Hero from './Hero/Hero'
import UploadPrayerTimes from './UploadPrayerTimes/UploadPrayerTimes'
import UploadJummahTimes from './UploadJummahTimes/UploadJummahTimes'
import './App.css';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute'
import SocialFeedUpload from './SocialFeedUpload/SocialFeedUpload';
import JanazaUpload from './JanazaUpload/JanazaUpload';
import HadithOfTheDay from './HadithOfTheDay/HadithOfTheDay';
import AddVerseOfTheDay from './AddVerseOfTheDay/AddVerseOfTheDay.js';

function App() {
  return (
    <>
    <Router>
      <AuthProvider>
        <Switch>
          <Route path="/" exact component={Login}/>
          <PrivateRoute path="/welcome" component={Hero}/>
          <Route path="/uploadPrayerTimes" component={UploadPrayerTimes}/>
          <Route path="/uploadJummahTimes" component={UploadJummahTimes}/>
          <Route path="/socialFeedUpload" component={SocialFeedUpload}/>
          <Route path="/janazaUpload" component={JanazaUpload}/>
          <Route path="/hadithOfTheDay" component={HadithOfTheDay}/>
          {/* <Route path="/addVerseOfTheDay" component={AddVerseOfTheDay}/> */}
        </Switch>
      </AuthProvider>
    </Router>
    </>
  );
}

export default App;
